<template>
    <div class="admin_page_body">
        <div class="settle_main">
            <div class="title">账户概览</div>

            <div class="list">
                <div class="item">
                    <span class="text">已结算金额（元）</span>
                    <div class="info">
                        <span class="price">2，233.09</span>
                    </div>
                </div>

                <div class="line"></div>

                <div class="item">
                    <span class="text">待结算金额（元）</span>
                    <div class="info">
                        <span class="price">2，233.09</span>
                        <a href="Javascript:void(0)" class="link" @click="$router.push('/admin/settle/wait')">待结算订单</a>
                    </div>
                </div>
            </div>
        </div>

        <div class="admin_page_item pr0">
            <div class="data_search">
                <el-form ref="listQuery" :inline="true" :model="listQuery" size="small" label-width="72px">
                    <el-form-item label="订单编号：" prop="code">
                        <el-input v-model="listQuery.code" placeholder="请输入订单编号"></el-input>
                    </el-form-item>

                    <el-form-item label="交易流水：" prop="title">
                        <el-input v-model="listQuery.title" placeholder="请输入支付单号/结算流水"></el-input>
                    </el-form-item>

                    <el-form-item label="结算日期：" prop="date">
                        <el-date-picker
                                v-model="listQuery.date"
                                type="daterange"
                                range-separator="~"
                                start-placeholder="开始时间"
                                end-placeholder="结束时间"
                                value-format="yyyy-MM-dd"
                                :picker-options="dateOptions">
                        </el-date-picker>
                    </el-form-item>

                    <el-form-item label="支付时间：" prop="date">
                        <el-date-picker
                                v-model="listQuery.date"
                                type="daterange"
                                range-separator="~"
                                start-placeholder="开始时间"
                                end-placeholder="结束时间"
                                value-format="yyyy-MM-dd"
                                :picker-options="dateOptions">
                        </el-date-picker>
                    </el-form-item>

                    <el-form-item class="last">
                        <el-button type="primary" :loading="isSearchLoading"  @click="toSearchList">查 询</el-button>
                        <el-button @click="$refs['listQuery'].resetFields()">重 置</el-button>
                    </el-form-item>
                </el-form>
            </div>

            <div class="data_group mt18">
                <el-button type="primary" size="small" style="width: 108px">导出</el-button>
            </div>

            <div class="data_table mt24">
                <el-table :data="listData" style="width: 100%" v-loading="isRequestLoading">
                    <el-table-column type="selection" width="50" align="center"></el-table-column>
                    <el-table-column prop="code" label="订单编号" width="170"></el-table-column>
                    <el-table-column prop="code" label="订单金额" width="170">
                        <template>
                            <span style="color: #5DAD03">¥23000</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="code" label="支付金额" width="170">
                        <template>
                            <span style="color: #5DAD03">¥23000</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="code" label="结算金额" width="170">
                        <template>
                            <span style="color: #5DAD03">¥23000</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="title" label="支付单号" width="160" align="center"></el-table-column>
                    <el-table-column prop="goodName" label="结算流水" min-width="120px" align="center"></el-table-column>
                    <el-table-column prop="stopAt" label="支付时间" min-width="140px" align="center"></el-table-column>
                    <el-table-column prop="stopAt" label="结算日期" min-width="140px" align="center"></el-table-column>
                    <el-table-column label="订单状态" min-width="100px" align="center">
                        <template slot-scope="scope">
                            <div class="status blue" v-if="scope.row.auditStatus === 1">待审核</div>
                            <div class="status green" v-if="scope.row.auditStatus === 2">已通过</div>
                            <div class="status info" v-if="scope.row.auditStatus === 3">已关闭</div>
                            <div class="status red" v-if="scope.row.auditStatus === 4">已驳回</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="address" label="操作" align="center" width="100" fixed="right">
                        <template slot-scope="scope">
                            <el-button type="text" @click="$router.push({path: '/supplyBuying/buying/detail', query: {id: scope.row.id}})">订单详情</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>

            <pagination :size="listQuery.pageSize" :total="pages.total" :current="listQuery.page" :pages="pages.pages" @pageChange="pageChange"/>
        </div>
    </div>
</template>

<script>
  import {mapState} from "vuex";
  import {imageURL} from "../../../utils/config";
  import {getBuying, postBuyingCancel} from '../../../api/admin/supplyBuying'
  const pagination = () => import('../../../components/pagination/admin')
  export default {
    name: "adminSettleIndex",
    data () {
      return {
        listQuery: {
          code: '',
          title: '',
          goodsName: '',
          isRun: '',
          page: 1,
          pageSize: 10
        },
        pages: {
          total: 0,
          pages: 0
        },
        isSearchLoading: false,
        isRequestLoading: false,
        listData: [],
        imageURL: imageURL,
        dateOptions: {
          disabledDate(time) {
            return time.getTime() > Date.now()
          }
        },
      }
    },
    computed: {
      ...mapState({
        listKeepAlive: state => state.app.listKeepAlive,
      })
    },
    activated () {
      if (this.listKeepAlive.isInitPageNo) {
        this.$store.commit('changeScrollTop');
        this.listQuery.page = 1
      }
      if (this.listKeepAlive.isReset) {
        this.$refs['listQuery'].resetFields()
      }
      if (this.listKeepAlive.isQueryList) {
        this.getList()
      }
    },
    methods: {
      getList () {
        this.isRequestLoading = true
        getBuying(this.listQuery).then(res => {
          this.isRequestLoading = false
          this.isSearchLoading = false
          if (res.code === 0) {
            this.listData = res.data.list
            this.pages.total = res.data.total
            this.pages.pages = res.data.pages
          }
        }).catch(() => {
          this.isRequestLoading = false
          this.isSearchLoading = false
        })
      },
      pageChange (e) {
        this.listQuery.page = e.current
        this.listQuery.pageSize = e.size
        this.getList()
      },
      toSearchList () {
        this.listQuery.page = 1
        this.isSearchLoading = true
        this.getList()
      },
      getPriceStatus (status) {
        switch (status) {
          case 1:
            return '可议价';
          case 2:
            return '不可议价';
          case 3:
            return '面议';
        }
      },
      cancelData (id) {
        this.$confirm('此操作将永久关闭该采购单, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          closeOnClickModal: false,
          type: 'warning'
        }).then(() => {
          postBuyingCancel(id).then(res => {
            if (res.code === 0) {
              this.$message({
                message: '采购单关闭成功',
                type: 'success',
                showClose: true,
              });
              this.getList()
            }
          })
        }).catch(() => {});
      }
    },
    components: {pagination}
  }
</script>
